<template>
  <a :href="notifUrl" class="notif--item" @click="clickHandler">
    <div class="notif--image-wrapper">
      <img class="notif--image" :src="notification.photoUrl" />
      <div :class="{ unread: !notification.isRead }"></div>
    </div>
    <div class="d-flex justify-space-between" style="width: 100%">
      <div class="notif--text multiline-ellipsis-3">
        {{ notification.notificationBody }}
      </div>
      <div class="notif--ago">
        {{ formattedCreatedAt }}
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'notification-item',
  props: ['notification'],
  computed: {
    formattedCreatedAt() {
      return this.$date.format(this.notification.createdAt, 'dd MMMM HH:mm');
    },
    notifUrl() {
      if (
        this.notification.notifType === 'BOOKING' ||
        this.notification.notifType === 'BOOKING_REQUEST' ||
        this.notification.notifType === 'SELL_TRANSACTION' ||
        this.notification.notifType === 'RENT_TRANSACTION' ||
        this.notification.notifType === 'TOUR_PROG'
      ) {
        return `/mybooking/detail?type=${this.notification.notifType}&uuid=${this.notification.applicationUuid}`;
      } else {
        return `/inbox/${this.notification.messageThreadUuid}`;
      }
    },
  },
  methods: {
    clickHandler() {
      if (!this.notification.isRead) {
        this.$store.commit(
          'v2/notification/READ_NOTIFICATION_OVERVIEW',
          this.notification.messageThreadUuid,
        );
        this.$store.commit('v2/notification/CUT_UNREAD_COUNT');
      }
    },
  },
};
</script>
